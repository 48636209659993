<template>
  <div
    id="app"
    data-server-rendered="true"
  >
    <router-view />
    <footer>
        <p>
          &copy; {{ new Date().getFullYear() }}
          -
          <strong>Cyclo Truck</strong>
        </p>
        <p>
          <router-link
            :to="{ name: 'legals', }"
            title="Mentions légales"
          >
            Mentions légales
          </router-link>
          -
          <router-link
            :to="{ name: 'cgl', }"
            title="Conditions générales de location"
          >
            Conditions générales de location
          </router-link>
          -
          <a
            target="_blank"
            href="https://heckmann.dev"
          >
            Heckmann Développement
          </a>
        </p>
      </footer>
  </div>
</template>

<script>
// import Truck from "@/components/Truck.vue";

export default {
  name: "App",
  components: {
    // Truck,
  },
};
</script>

<style lang="scss">
footer {
  margin-top: 2rem;
  font-size: 1rem;
  text-align: center;
  padding-bottom: 1rem;
  a {
    color: var(--color-white);
  }
}

.text-center {
  text-align: center;
}
</style>
